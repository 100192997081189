<template>
  <div>
    <b-card class="card search-ingredients" header="Pridať ďalší tovar">
      <b-button @click="onClose" variant="secondary" class="close-button">×</b-button>
      <b-input-group class="ingredient-search">
        <b-form-input :ref="'focusInput'" @update="searchIngredient" debounce="500" type="search" v-model="searchInput"
                      placeholder="Vyhľadajte tovar" />
        <svg class="search-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6164 10.9301L8.76034 7.95969C9.49467 7.08675 9.89702 5.98841 9.89702 4.845C9.89702 2.17351 7.72351 0 5.05203 0C2.38054 0 0.207031 2.17351 0.207031 4.845C0.207031 7.51648 2.38054 9.68999 5.05203 9.68999C6.05494 9.68999 7.01067 9.38749 7.82779 8.81326L10.7055 11.8062C10.8258 11.9311 10.9876 12 11.1609 12C11.325 12 11.4807 11.9374 11.5989 11.8237C11.85 11.5821 11.858 11.1814 11.6164 10.9301ZM5.05203 1.26391C7.02668 1.26391 8.63311 2.87034 8.63311 4.845C8.63311 6.81965 7.02668 8.42608 5.05203 8.42608C3.07737 8.42608 1.47094 6.81965 1.47094 4.845C1.47094 2.87034 3.07737 1.26391 5.05203 1.26391Z" fill="#C4C4C4"/>
        </svg>
      </b-input-group>


      <ul class="list-unstyled mt-3">
        <li v-for="(item, i) in policiesSearch" :key="'ingredientSearch'+i"
            @click="onToggleSelected(item.oid)" :class="{'selected': isQueued(item.oid)}">
          <div class="left-side">
            <VendorBadge :vendor-id="item.vendor_id"/>
            <div class="policy-title">
              <div class="policy-code">
                #{{ item.oid }}
              </div>
              <span class="font-weight-bolder">{{ item.name }}</span>
            </div>
          </div>
          <div class="right-side">
            <div>{{ formatPrice(item.price) }} &euro; </div>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 0C3.14005 0 0 3.14005 0 7C0 10.86 3.14005 14 7 14C10.86 14 14 10.86 14 7C14 3.14005 10.86 0 7 0Z" fill="#C4C4C4"/>
              <path d="M10.5477 5.51654L6.75597 9.30814C6.64221 9.42189 6.49289 9.47914 6.34357 9.47914C6.19425 9.47914 6.04492 9.42189 5.93117 9.30814L4.03537 7.41234C3.80722 7.1843 3.80722 6.81558 4.03537 6.58754C4.26341 6.35939 4.63202 6.35939 4.86017 6.58754L6.34357 8.07094L9.72287 4.69174C9.95091 4.46359 10.3195 4.46359 10.5477 4.69174C10.7757 4.91978 10.7757 5.28839 10.5477 5.51654Z" fill="white"/>
            </svg>
          </div>
        </li>
      </ul>

      <b-button variant="empty" @click="onDelete">Odznačiť všetky</b-button>
      &nbsp;
      <b-button @click="onSubmit" class="float-right" :disabled="!selected.length">Pridať {{selected.length}}
        <template v-if="selected.length === 1">produkt</template>
        <template v-else-if="selected.length === 2">produkty</template>
        <template v-else-if="selected.length === 3">produkty</template>
        <template v-else-if="selected.length === 4">produkty</template>
        <template v-else>produktov</template>
      </b-button>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapActions, mapGetters, mapMutations} from "vuex"
import format from "@/plugins/app/formatters"
import VendorBadge from "@/plugins/app@enums/vendor-badge"

export default Vue.extend({
  components: {VendorBadge},
  name: 'search-ingredients-modal',
  data: function () {
    return {
      searchInput: "",
      selected: [],
    }
  },
  computed: {
    ...mapGetters('policies', ['policiesSearch'])
  },
  mounted() {
    this.$nextTick(_ => this.$refs.focusInput.focus())
  },
  methods: {
    ...mapActions('policies', ['searchPolicy']),
    ...mapActions('cart', ['addItemToCart']),
    ...mapMutations('policies', ['clearSearchPolicies']),

    searchIngredient() {
      if(this.searchInput.length < 3)
        this.clearSearchPolicies()
      else
        this.searchPolicy(this.searchInput)
    },

    onToggleSelected(oid) {
      if(!this.isQueued(oid))
        this.selected.push({oid})
      else
        this.selected = this.selected.filter(v => v.oid !== oid)
    },

    isQueued(oid) {
      return !!this.selected.filter(v => v.oid === oid).length
    },

    onSubmit: function () {
      this.addItemToCart(this.selected)
      this.clearSearchPolicies()
      this.$emit('close')
    },
    onDelete: async function () {
      this.selected = []
    },
    onClose: function() {
      this.$emit('close')
    },

    formatPrice: format.price,
  }
})
</script>

<style lang="scss" scoped>
  .search-ingredients{
    .btn-empty {
      color: #6A6A6A;
      padding: 0;
    }
    ul {
      border-bottom: 1px solid #F1F1F1;
      li {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        border-radius: 4px;
        margin-bottom: 5px;
        padding: 0 10px;
        .right-side{
          display: flex;
          align-items: center;
          svg {
            margin-left: 10px;
          }
        }
        .left-side {
          display: flex;
          align-items: center;
          .vendor-badge {
            margin-right: 10px;
          }
        }
        &:hover {
          background-color:#E7F9EE;
        }
        &.selected {
          svg {
            path {
              &:first-child{
                fill: #13BF58;
              }
            }
          }
        }
      }
    }
  }
  .selected {
    background: #E7F9EE;
  }
  .close-button {
    color: #C4C4C4;
    padding: 0;
    background-color: transparent;
    position: absolute;
    top: 23px;
    right: 30px;
    font-size: 24px;
    border: none;
    font-weight: 300;
  }
</style>
