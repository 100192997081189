<template>
  <div>
    <div class="container mt-5 mb-5">
      <div class="mb-md-4 pb-5">
        <b-card class="card cart">
          <h5>Nákupný zoznam - Krok 1: Receptúry</h5>
          <p style="font-size: 80%">Skontrolujte zoznam receptúr pre ktoré vytvoríme nákupný zoznam. Po stlačení tlačidla vytvoriť sumár sa suroviny z jednotlivých receptúr spočítajú a v ďalšom kroku ich budete môcť podľa potreby odstrániť.</p>
          <div class="d-flex cart-header">
            <div class="cart-header--title">Receptúra / Ingrediencie</div>
            <div class="sub">Množstvo</div>
            <div class="sub">Plán</div>
            <div class="sub">
              <b-dropdown right class="policy-drop">
                <template #button-content>
                  <span>...</span>
                </template>
                <b-dropdown-item @click.prevent="onRemoveCart">Vymazať zoznam</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="recipe-item" v-for="(item, i) in cart.recipes" :key="i" :class="{ products: item.group_id === 'null'}">
            <template v-if="item.group_id !== 'null'">
              <div class="d-flex recipe-item--content">
                <div class="recipe-item--content__title">
                  <div>
                    <b-button @click="onToggle(item.group_id)" variant="empty" :class="{toggled: toggleState[item.group_id], 'toggle-btn': true}">
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.83333 5C5.83333 5.17922 5.76331 5.35841 5.62354 5.49505L1.22336 9.79483C0.943457 10.0683 0.489638 10.0683 0.209845 9.79483C-0.0699482 9.52142 -0.0699482 9.07804 0.209845 8.80449L4.10337 5L0.209981 1.19548C-0.0698122 0.921957 -0.0698122 0.478621 0.209981 0.205233C0.489774 -0.0684214 0.943593 -0.0684214 1.2235 0.205233L5.62368 4.50494C5.76346 4.64165 5.83333 4.82084 5.83333 5Z" fill="#C4C4C4"/>
                      </svg>
                    </b-button>
                  </div>
                  <div class="title">{{item.name}}</div>
                </div>
                <div class="sub">{{item.portions }} {{ formatPortions(item.portions) }}</div>
                <div class="sub"><div class="badge-item">{{item.plan}}</div></div>
                <div class="sub">
                  <b-dropdown right class="policy-drop">
                    <template #button-content>
                      <span>...</span>
                    </template>
                    <b-dropdown-item @click.prevent="onRemoveCartRecipe(item.group_id)">Vymazať recept</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </template>
            <b-collapse :visible="item.group_id !== 'null' ? toggleState[item.group_id] : true">
              <div v-for="(product, ii) in item.products" :key="`product${ii}`" class="jump-wrap">
                <b-dropdown right class="policy-drop absolute-drop">
                  <template #button-content>
                    <span>...</span>
                  </template>
                  <b-dropdown-item @click.prevent="onRemoveCartItem(product.id)">Vymazať tovar</b-dropdown-item>
                </b-dropdown>
                <div class="stop-fucking-jumping">
                  <div class="d-flex collapse-item">
                    <div class="collapse-item--title">

                      <div class="flex-grow-1">
                        {{product.label}}
                      </div>
                    </div>
                    <div class="sub">{{formatIngredientVolume(product)}}</div>
                    <div class="sub">
                      <div class="badge-item" v-if="item.plan">{{item.plan}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </b-card>
      </div>
    </div>
    <SubmitFooter :on-submit="onSubmit" submit-text="Vytvoriť sumár"/>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapActions, mapGetters, mapMutations} from "vuex"
import format from '@/plugins/app/formatters'
import SearchProductsModal from "@/plugins/app/cart/search-products-modal"
import SubmitFooter from "@/plugins/app/_layouts/components/submit-footer"

export default Vue.extend({
  components: {SubmitFooter},
  data: function () {
    return {
      toggleState: {}
    }
  },
  watch: {
    cart: function () {
      this.cart.recipes.forEach( (item) => {
        if(!this.toggleState[item.group_id])
          this.toggleState[item.group_id] = false

        if(item.group_id === 'null') {
          this.toggleState[item.group_id] = true
        }

        this.$forceUpdate()
      })
    }
  },
  computed: {
    ...mapGetters('cart', ['cart']),
  },
  async mounted() {
    console.log('cart mounted')
    this.$forceUpdate()
  },
  methods: {
    ...mapActions('cart', [
      'removeCart', 'removeCartRecipe',
      'removeCartItem', 'changeItemPortions',
      'submitCart'
    ]),
        ...mapMutations('cart', ['updateCart']),
    onChangePortions(quantity, id) {
      this.changeItemPortions([{id, quantity}])
    },
    onToggle(id) {
      this.toggleState[id] = !this.toggleState[id]
      this.$forceUpdate()
    },
    onOpenModal() {
      this.$modal.show( SearchProductsModal )
    },

    onRemoveCart() {
      this.removeCart()
    },
    onRemoveCartRecipe(recipeId) {
      this.removeCartRecipe(recipeId)
    },
    onRemoveCartItem(id) {
      this.removeCartItem(id)
    },

    onSubmit() {
      this.navTo('cart2')
    },

    navTo: function (to) {
      this.$router.push(to)
    },

    formatDay: format.day,
    formatDayId: format.dayId,
    formatDayName: format.dayName,
    formatNow: format.now,
    formatPrice: format.price,
    formatIsPast: format.isPast,
    formatIngredientVolume: format.ingredientVolume,
    formatPortions: format.portions,
  }
})
</script>
<style lang="scss">
.dropdown-menu {
  .dropdown-item {
    padding:8px;
  }
}


.flex-grow-1 {
  padding-left: 20px;
}

.jump-wrap{
  position: relative;

  @for $i from 1 through 999 {
    &:nth-last-child(#{$i}) {
      z-index: #{$i};
    }
  }

  .stop-fucking-jumping {
    height: 40px;
    overflow: hidden;
    position: relative;
    .collapse-item {
      background-color: #FCFCFC;
    }
  }
  .absolute-drop{
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 1;

    .dropdown-menu{
      z-index: 10;
    }
    button{
      border: none;
      color: #C4C4C4;
      background-color: transparent!important;
      &:focus{
        background-color: transparent!important;
      }
      &:active{
        background-color: transparent!important;
      }
      span {
        display: block;
        margin-top: -6px;
        color: #A6A6A6;
      }
      &:after {
        display: none;
      }
    }
  }
}

.toggle-btn{
  box-shadow:none !important;
  outline:0px !important;
  transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  &.toggled {
    transform: rotate(90deg);
  }
}

.old-price{
  text-decoration: line-through;
}
.total {
  padding-top: 15px;
  border-top: 1px solid #F1F1F1;

  .total-right{
    display: flex;
    align-items: center;
    position: absolute;
    right: 30px;
    .dropdown-toggle{
      margin-left: 20px;
      background-color: transparent !important;
      border: none;
      padding: 0.375rem 0.75rem;
      &:after {
        display: none;
      }
      span{
        display: block;
        margin-top: -8px;
        color: #A6A6A6;
      }
    }
  }
  .total-title {
    font-size: 16px;
    font-weight: 500;
  }
  .total-actions{
    .btn-secondary {
      padding: 8px 10px;
      font-size: 12px;
    }
    .price{
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
